<template>
	<div class="switcher_container" :style="computed_style">
		<div class="switcher_title_container">
			<slot></slot>
		</div>
		<div class="switcher_state_container">
			<div :class="'switcher_state'+computed_classes_switcher_state" :onClick="()=>{toggle()}">
				<div :class="'switcher_state_button'+computed_classes_switcher_button">
					<i :class="computed_icon"
						v-if="disabledIcon != null && typeof disabledIcon != 'undefined' || enabledIcon != null && typeof enabledIcon != 'undefined'"
					></i>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import ComponentImportedName from './components/ComponentImportedName.vue'

	export default {
		name: 'Switcher',

		components: {
			// ComponentImportedName,
		},

		props: {
			value: Boolean,
			save: Function,
			disabledIcon: String,
			enabledIcon: String,
		},
		
		data() {
			return {
				inputValue:false,
			}
		},

		created: function() {

		},

		mounted() {
			// console.log("switcher: "+String(this.value))
			this.inputValue = this.value;
		},

		filters: {
			filterExample: function(value) {
				let str = value;
				return str;
			}
		},

		computed: {
			computed_style: function() {
				let computed_style_str = "";
				return computed_style_str;
			},
			computed_classes_switcher_state: function() {
				let computed_classes_switcher_state_str = "";
				if(this.inputValue) {
					computed_classes_switcher_state_str += " switcher_enabled";
				} else {
					computed_classes_switcher_state_str += " switcher_disabled";
				}
				return computed_classes_switcher_state_str;
			},
			computed_classes_switcher_button: function() {
				let computed_classes_switcher_button_str = "";
				if(this.inputValue) {
					computed_classes_switcher_button_str += " switcher_button_enabled";
					computed_classes_switcher_button_str += " enableSwitcherAnim";
				} else {
					computed_classes_switcher_button_str += " switcher_button_disabled";
					computed_classes_switcher_button_str += " disableSwitcherAnim";
				}
				return computed_classes_switcher_button_str;
			},
			computed_icon: function() {
				if(this.getValue()) {
					return this.enabledIcon;
				} else {
					return this.disabledIcon;
				}
			}
		},


		updated() {
			// this.saveValue();
		},

		methods: {
			toggle: function () {
				this.inputValue = !this.inputValue;
				this.saveValue();
			},
			getValue: function() {
				return this.inputValue;
			},
			saveValue() {
				if(this.save != null && typeof this.save != "undefined") { this.save(); }
			}
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.switcher_container {
		width:calc(100% - 0px);
		display:flex;
		align-items:center;
		justify-content:center;
		padding:7.5px 0 7.5px 0;
	}
	.switcher_title_container {
		flex:1;
		text-align:right;
	}
	.switcher_state_container {
		flex:1;
		align-self:stretch;
		justify-self:stretch;
		display:flex;
		align-items:center;
		/* justify-content:flex-start; */
		justify-content:center;
		height:40px;
		padding:0 0 0 15px;
	}
	.switcher_state {
		display:flex;
		background-color:#cbcfd5;
		border-top-left-radius:100px;
		border-bottom-left-radius:100px;
		border-top-right-radius:100px;
		border-bottom-right-radius:100px;
		width:90px;
		align-self:stretch;
		align-items:center;
		justify-content:center;
		position:relative;
		cursor:pointer;
	}

	.switcher_enabled {
		background-color:#5FB14E !important;
	}
	.switcher_disabled {
		background-color:#6319F9 !important;
	}

	.switcher_state_button {
		display:flex;
		/* background-color:#FDEF54; */
		background-color:transparent;
		color:#F7FF39;
		text-shadow: 1px 2px 0px rgba(255, 255, 255,0.75);
		border-style:solid;
		border-width:0;
		border-color:#2D3033;
		border-radius:50%;
		width:calc(45px - 2px);
		height:calc(45px - 2px);
		margin-top:-2px;
		align-self:stretch;
		align-items:center;
		justify-content:center;
		position:absolute;
		top:0;
		/* left:0; */
		float:right;
		font-size:25px;
	}

	.switcher_button_enabled {
		/* background-color:#C4D67A !important; */
		/* background-color:#cbcfd5 !important; */
		/* background-color:#FDEF54 !important; */
		/* background-color:#cbcfd5 !important; */
		text-shadow: 1px 1px 0px #499025;
		/* box-shadow: -1px 0px 1px 1.5px rgba(26, 75, 35,0.75); */
	}
	.switcher_button_disabled {
		/* background-color:#cbcfd5 !important; */
		/* text-shadow: 1px 2px 0px rgba(255, 255, 255,0.75); */
		/* box-shadow: 1.5px 0px 2px 1.5px rgba(109, 49, 47,0.75); */
		/* background-color:#E54141 !important; */
		color:#DFACFE !important;
		/* text-shadow: 1px 2px 0px rgba(252, 101, 100,0.75); */
		text-shadow: 1px 2px 0px #8159FC;
	}

	
	.disableSwitcherAnim {
		
		animation: disableSwitcher 0.15s forwards;
		animation-timing-function: ease;
		/* animation-iteration-count: infinite; */
	}

	@keyframes disableSwitcher {
		from {
			left:54%;
		}
		to {
			left:-4%;
		}
	}
	
	.enableSwitcherAnim {
		
		animation: enableSwitcher 0.15s forwards;
		animation-timing-function: ease;
		/* animation-iteration-count: infinite; */
	}

	@keyframes enableSwitcher {
		from {
			left:-4%;
		}
		to {
			left:54%;
		}
	}
</style>
