<template>
	<div class="start_step_container">
		<div :class="'start_step_bar'" ref="start_step_bar" :style="computed_style">
		</div>
	</div>
</template>

<script>
	// import ComponentImportedName from './components/ComponentImportedName.vue'

	export default {
		name: 'StartStep',

		components: {
			// ComponentImportedName,
		},

		props: {
			// messages: Object,
			onLoadingAnimError: Function,
			onLoadingAnimSuccess: Function,
			unlocked: String,
		},

		
		data() {
			return {
				animStep:1,
				random_duration: [],
				loading_anim_error: false,
				isUnlocked: false,
			}
		},

		created: function() {
		},

		mounted() {


			this.isUnlocked = this.unlocked === 'true';
			// console.log("unlocked: "+this.unlocked);
			// console.log("isUnlocked: "+this.isUnlocked);
			// console.log("this.isUnlocked: "+this.isUnlocked);

			this.random_duration[0] = ((Math.random() * 1.15) + 0.5).toFixed(2);
			this.random_duration[1] = ((Math.random() * 2.5) + 1.75).toFixed(2);
			this.random_duration[2] = ((Math.random() * 7.75) + 4.25).toFixed(2);
			this.random_duration[3] = ((Math.random() * 3.25) + 2.25).toFixed(2);
			this.random_duration[4] = ((Math.random() * 2.15) + 1.5).toFixed(2);
			this.random_duration[5] = ((Math.random() * 1.15) + 0.75).toFixed(2);



			this.loading_anim_error = localStorage.getItem("loading_anim_error") === 'true';
			// console.log("this.loading_anim_error: "+this.loading_anim_error);
			if(!this.loading_anim_error) {
				this.startAnim();
			} else {
				if(this.onLoadingAnimError != null && this.onLoadingAnimError != 'undefined') {
					setTimeout(function(){
						let start_step_bar = this.$refs.start_step_bar;
						start_step_bar.classList.add("start_step_bar_error");
						this.onLoadingAnimError();
					}.bind(this),this.random_duration[5]*1000);
				}
			}
		},

		filters: {
			filterExample: function(value) {
				let str = value;
				return str;
			}
		},

		computed: {
			computed_style: function() {
				let computed_style_str = "";
				if(this.loading_anim_error) {
					computed_style_str += 'width:calc(90% - 0px);';
				}
				return computed_style_str;
			},
			computed_classes: function() {
				let computed_classes_str = "";
				// computed_classes_str += " loading_bar_anim_"+this.animStep;
				// console.log(computed_classes_str);
				return computed_classes_str;
			},
		},

		methods: {
			startAnim: function () {

				// console.log("this.random_duration[0]: "+this.random_duration[1]);
				// console.log("this.random_duration[1]: "+this.random_duration[1]);
				// console.log("this.random_duration[2]: "+this.random_duration[2]);
				// console.log("this.random_duration[3]: "+this.random_duration[3]);
				// console.log("this.random_duration[4]: "+this.random_duration[4]);
				// console.log("this.random_duration[5]: "+this.random_duration[5]);

				let start_step_bar = this.$refs.start_step_bar;

				setTimeout(function(){
					this.animStep = 1;
					let anim_duration =String(this.random_duration[this.animStep])+"s";
					start_step_bar.style.animationDuration = anim_duration;
					start_step_bar.classList.add("loading_bar_anim_"+this.animStep);

					setTimeout(function(){
						start_step_bar.classList.remove("loading_bar_anim_"+this.animStep);
						this.animStep = 2;
						anim_duration = String(this.random_duration[this.animStep])+"s";
						start_step_bar.style.animationDuration = anim_duration;
						start_step_bar.classList.add("loading_bar_anim_"+this.animStep);
						setTimeout(function(){
							start_step_bar.classList.remove("loading_bar_anim_"+this.animStep);
							this.animStep = 3;
							anim_duration = String(this.random_duration[this.animStep])+"s";
							start_step_bar.style.animationDuration = anim_duration;
							if(!this.isUnlocked) {
								start_step_bar.classList.add("loading_bar_anim_"+this.animStep);
							} else {
								start_step_bar.classList.add("loading_bar_anim_"+this.animStep+"_success");
							}
							setTimeout(function(){
								setTimeout(function(){
									this.finishAnim();
								}.bind(this),this.random_duration[this.animStep+1]*1000);
							}.bind(this),this.random_duration[this.animStep]*1000);
						}.bind(this),this.random_duration[this.animStep]*1000);
					}.bind(this),this.random_duration[this.animStep]*1000);
				}.bind(this),this.random_duration[0]*1000);
			},
			finishAnim: function() {
				// alert("ok");
				let start_step_bar = this.$refs.start_step_bar;
				if(!this.isUnlocked) {
					start_step_bar.classList.add("start_step_bar_error");
					this.saveAfterFinished();
					if(this.onLoadingAnimError != null && this.onLoadingAnimError != 'undefined') {
						setTimeout(function(){
							this.onLoadingAnimError();
						}.bind(this),this.random_duration[this.animStep+2]*1000);
					}
				} else {
					start_step_bar.classList.add("start_step_bar_success");
					if(this.onLoadingAnimSuccess != null && this.onLoadingAnimSuccess != 'undefined') {
						this.onLoadingAnimSuccess();
					}
				}
			},
			saveAfterFinished: function() {
				localStorage.setItem("loading_anim_error","true");
			},
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.start_step_container {
		width:calc(90% - 0px);
		display:flex;
		align-items:center;
		justify-content:flex-start;
		flex-direction: row;
		background-color:#482997;
		/* border-radius: 1.5px; */
	}

	.start_step_bar {
		width:0%;
		min-height:50px;
		background-color:#FEBA33;
		display:flex;
		align-items:flex-start;
		justify-content:center;
		flex-direction: row;
		text-align:center;
		/* border-radius: 1.5px; */
	}

	
	.start_step_bar_error {
		background-color:#E54141 !important;
	}
	
	.start_step_bar_success {
		background-color:#59AF54 !important;
	}

	.loading_bar_anim_1 {
		animation: loading_bar_1 forwards;
		animation-timing-function: cubic-bezier(.17,.67,.35,0.2);
	}

	.loading_bar_anim_2 {
		animation: loading_bar_2 forwards;
		animation-timing-function: cubic-bezier(.17,.67,.35,0.2);
	}

	.loading_bar_anim_3 {
		animation: loading_bar_3 forwards;
		animation-timing-function: cubic-bezier(.17,.67,.35,0.2);
	}

	.loading_bar_anim_3_success {
		animation: loading_bar_3_success forwards;
		animation-timing-function: cubic-bezier(.17,.67,.35,0.2);
	}

	@keyframes loading_bar_1 {
		from {
			width:0%;
		}
		to {
			width:25%;
		}
	}

	@keyframes loading_bar_2 {
		0% {
			width:25%;
		}
		40%,50% {
			width:40%;
		}
		100% {
			width:60%;
		}
	}

	@keyframes loading_bar_3 {
		0% {
			width:60%;
		}
		25%,35% {
			width:65%;
		}
		100% {
			width:90%;
		}
	}

	@keyframes loading_bar_3_success {
		0% {
			width:60%;
		}
		25%,35% {
			width:65%;
		}
		100% {
			width:100%;
		}
	}

	.rotate_anim {
		animation: rotate forwards;
		animation-duration: 4s;
		/* animation-timing-function: ease-out; */
		animation-timing-function: ease;
		animation-iteration-count: infinite;
		/* position:absolute; */
	}

	@keyframes rotate {
		0% {
			transform: rotate(0deg); 
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>
