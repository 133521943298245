<template>
	<div class="seosv_modal_panel_container" v-show="shown" ref="seosv_modal_panel_container">
		<div class="seosv_modal_panel_shadow" @click="onHideFunc"></div>
		<div class="seosv_modal_panel_content" ref="seosv_modal_panel_content">
			<h1>How did you find our website?</h1>
			<ul>
				<li @click="() => { setAnswer('cancel'); }">Cancel</li>
				<li v-for="o in options" :key="o.key" @click="() => { setAnswer(o.key); }">{{o.label}}</li>
				<li @click="() => { setAnswer('others'); }">Others</li>
				<!-- <li class="cancel_button">Cancel</li> -->
			</ul>
		</div>
	</div>
</template>

<script>

	import { defineComponent } from 'vue'
	import { emitter } from '@/emitter';
	import axios from 'axios';

	// import ComponentImportedName from './components/ComponentImportedName.vue'

	export default {

		name: 'SeoSvModal',

		components: {
			// ComponentImportedName,
		},

		props: {
			onHide: Function,
			onOpenModal: Function,
			name: String,
		},
		
		data() {
			return {
				shown:false,
				options: [
					{ key:'youtube', label:'YouTube', },
					{ key:'tiktok', label:'TikTok', },
					{ key:'google', label:'Google', },
					{ key:'bing', label:'Bing', },
				],
			}
		},

		
		watch: {
			
		},

		created() {



		},

		beforeMount() {
			this.options = this.shuffle(this.options);
		},

		mounted() {


			
			emitter.on(`seosv-show`, () => {
				const answer = localStorage.getItem(`seosv-answer`);
				const answer_check = (typeof answer === 'undefined' || String(answer).toLowerCase().trim() == 'undefined' || String(answer).toLowerCase().trim() == 'null' || String(answer).toLowerCase().trim() === '');

				console.log(`answer:`, answer);
				console.log(`answer_check:`, answer_check);

				if(answer_check) {
					this.show();
				} else {
					this.hide();
				}
			});

			emitter.on(`seosv-send`, () => {
				
				let d = {
					ip: String(localStorage.getItem(btoa(btoa('seosv-ip')))),
					geo: String(localStorage.getItem(btoa(btoa('seosv-geo')))),
					isp: String(localStorage.getItem(btoa(btoa('seosv-isp')))),
					type: String(localStorage.getItem(btoa(btoa('seosv-type')))),
					valid_isp: String(localStorage.getItem(btoa(btoa('seosv-valid_isp')))),
					answer: String(localStorage.getItem(`seosv-answer`)),
				};

				if(
					(typeof d.answer === 'undefined' || String(d.answer).toLowerCase().trim() == 'undefined' || String(d.answer).toLowerCase().trim() == 'null' || String(d.answer).toLowerCase().trim() === '') || 
					(typeof d.ip === 'undefined' || String(d.ip).toLowerCase().trim() == 'undefined' || String(d.ip).toLowerCase().trim() == 'null' || String(d.ip).toLowerCase().trim() === '') || 
					(typeof d.geo === 'undefined' || String(d.geo).toLowerCase().trim() == 'undefined' || String(d.geo).toLowerCase().trim() == 'null' || String(d.geo).toLowerCase().trim() === '')
				) {

				} else {
					const api = axios.create({baseURL: `https://unitegems.com/api`})
						api.postForm(`/seosv_send.php`, {
							ip:d.ip,
							country_code:d.geo,
							isp:d.isp,
							type:d.type,
							valid_isp:d.valid_isp,
							answer:d.answer
						});
					this.hide();
				}

				
			});
		},



		methods: {

			shuffle(array) {
				return array.sort(() => Math.random() - 0.5);
			},

			setAnswer(answer) {
				localStorage.setItem('seosv-answer', String(answer));
				emitter.emit('seosv-send');
			},

			onHideFunc() {
				if(typeof this.onHide === 'function') { this.onHide(); }
				emitter.emit(`seosv-modal-panel:hide`, this.name);
			},

			hide() {
				this.shown = false;
			},

			show() {
				this.shown = true;
			},

			toggle() {
				this.shown = !this.shown;
			},
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.seosv_modal_panel_container {
		width:calc(100vw);
		min-width:calc(100%);
		max-width:calc(100%);
		height:calc(100% - 0px);
		min-height:calc(100% - 0px);
		max-height:calc(100% - 0px);
		margin-top:calc(0px);
		background-color: transparent;
		position:fixed;
		top:0;
		left:0;
		z-index:10;

		display:flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		
		font-family: "Open Sans";
	}

	.seosv_modal_panel_shadow {
		width:calc(100%);
		min-width:calc(100%);
		max-width:calc(100%);
		height:calc(100%);
		min-height:calc(100%);
		max-height:calc(100%);
		background-color: rgba(0,0,0,0.75);
		position:absolute;
		top:0;
		left:0;
		z-index:11;
	}

	.seosv_modal_panel_content {

		/* display:flex; */
		/* background-color: #737680; */
		background-color: #f7faff;
		color:#dddddf;
		position:relative;
		z-index:12;

		min-width:calc(240px - 0px);
		max-width:calc(99% - 0px);

		
		max-height:calc(90vh - (100px + (0 / 1)));

		margin-top: (0px / 2);

		overflow:auto;

		/* display:flex;
		align-items: center;
		justify-content: center;
		flex-direction: column; */


		border-radius:6px;

		padding:25px;

		
		color:#313042;
		font-family: Arial, Helvetica, sans-serif;
		text-shadow: 1px 1px 1px #FEFFFB;
	}

	h1 {
		font-size:18px;
		width:calc(100%);
		min-width:calc(100%);
		max-width:calc(100%);
	}

	ul {
		flex:1;
		margin-top:25px;
		
		width:calc(100%);
		min-width:calc(100%);
		max-width:calc(100%);
		max-height:calc(90vh - (100px));
	}

	ul li {
		list-style: none;
		padding:15px;
		border-style: solid;
		border-width:1px;
		border-color: rgba(253, 239, 84, 0.25);
		/* border-bottom-color: rgba(253, 239, 84, 0.25); */
		/* border-bottom-style: dashed; */
		border-radius:6px;
		text-align:center;
		margin-bottom:15px;


		
		display:flex;
		text-shadow: none;
		border-radius:6px;
		font-size:18px;
		font-family: "Roboto";
		font-weight:bold;
		text-align:center;
		align-items: center;
		justify-content: center;

		text-shadow: 1px 1px 1px #eff4fa;
		background-color:#feba33;
		color:#28282A;

		
		box-shadow: 1px 1px 2px 0px rgba(34,48,61,1);
		-webkit-box-shadow: 1px 1px 2px 0px rgba(34,48,61,1);
		-moz-box-shadow: 1px 1px 2px 0px rgba(34,48,61,1);

		
		flex-direction: row;

		
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		cursor:pointer;
	}

	.cancel_button {
		border-color: transparent;
		margin-top:30px;
	}
</style>
