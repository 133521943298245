<template>
	<div class="input_text_detail_container">
		<div class="input_text_detail_icon_container" :style="computed_style_icon" v-if="(icon != null && typeof icon != 'undefined') || (src != null && typeof src != 'undefined')">
			<i :class="icon" v-if="icon != null && typeof icon != 'undefined'"></i>
			<img :src="src" v-else-if="src != null && typeof src != 'undefined'" />
		</div>
		<input	
			:class="'input_text_detail'+computed_classes" 
			ref="input_text_detail" 
			type="text" 
			:placeholder="placeholder"
			:style="computed_style"
			:readonly="readonly"
			:value="computed_value"
		/>
	</div>
</template>

<script>
	// import ComponentImportedName from './components/ComponentImportedName.vue'

	export default {
		name: 'InputDetail',

		components: {
			// ComponentImportedName,
		},

		props: {
			value: String,
			icon: String,
			readonly: Boolean,
			placeholder: String,
			src: String,
			save: Function,
			onPressEnter: Function,
			fontSize: String,
			padding: String,
			iconWidth: String,
			textAlign:String,
		},

		created: function() {

		},
		
		data() {
			return {
				inputValue:"",
			}
		},

		mounted() {
			if(this.save != null && typeof this.save != "undefined") {
				let input_text_detail = this.$refs.input_text_detail;
				input_text_detail.addEventListener("change",this.setValue);
				input_text_detail.addEventListener("paste",this.setValue);
				input_text_detail.addEventListener("keyup",this.setValue);
				input_text_detail.addEventListener("keydown", function onEvent(event) {
					if (event.key === "Enter") {
						this.setValue();
						if(this.onPressEnter != null && typeof this.onPressEnter != "undefined") {
							this.onPressEnter();
						}
						return false;
					}
				}.bind(this));
				input_text_detail.addEventListener("ready",this.setValue);
				input_text_detail.addEventListener("load",this.setValue);
				this.setValue();
			}
		},

		filters: {
			filterExample: function(value) {
				let str = value;
				return str;
			}
		},

		computed: {
			computed_style: function() {
				let computed_style_str = "";
				if((this.icon != null && this.icon != 'undefined') || (this.src != null && this.src != 'undefined')) {
					computed_style_str += "border-radius: 0 6px 6px 0;";
				} else {
					computed_style_str += "border-radius: 6px;";
				}

				if(this.fontSize != null && typeof this.fontSize != 'undefined') {
					computed_style_str += "font-size: "+this.fontSize+";";
				}

				if(this.padding != null && typeof this.padding != 'undefined') {
					computed_style_str += "padding: "+this.padding+";";
				}

				if(this.textAlign != null && typeof this.textAlign != 'undefined') {
					computed_style_str += "text-align: "+this.textAlign+";";
				}

				if(this.readonly != null && typeof this.readonly != 'undefined') {
					if(this.readonly) {
						computed_style_str += "-moz-user-select: none; -webkit-user-select: none; -ms-user-select: none;";
					}
				}

				return computed_style_str;
			},
			computed_style_icon: function() {
				let computed_style_str = "";
				if(this.iconWidth != null && typeof this.iconWidth != 'undefined') {

					computed_style_str += "width: "+this.iconWidth+";";
					
					if(this.icon != null && this.icon != 'undefined') {
						computed_style_str += "font-size: 150%;";
					}
				}
				return computed_style_str;
			},
			computed_classes: function() {
				let computed_classes_str = "";
				
				if(this.readonly != null && typeof this.readonly != 'undefined') {
					if(this.readonly) {
						computed_classes_str += " input_text_detail_readonly";
					}
				}

				return computed_classes_str;
			},
			computed_value: function() {
				let computed_value_str = "";
				if(this.value != null && typeof this.value != "undefined") {
					computed_value_str = this.value;
				}
				// console.log("value: "+this.value);
				// console.log("value: "+computed_value_str);
				return computed_value_str;
			},
		},

		methods: {
			setValue: function() {
				let input_text_detail = this.$refs.input_text_detail;
				this.inputValue = String(input_text_detail.value);
				if(this.save != null && typeof this.save != "undefined") { this.save(); }
			},
			getValue: function () {
				return this.inputValue;
			},
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.input_text_detail_container {
		display:flex;
		align-items:stretch;
		justify-content:stretch;
		border-radius:6px 0 0 6px;
		flex:1;
		align-self:stretch;
		justify-self:stretch;
		margin:7.5px 7.5px 7.5px 7.5px;
	}

	.input_text_detail {
		width:calc(100% - 30px);
		border-style:solid;
		border-width:0;
		border-radius:2.5px;
		padding:15px;
		font-family: "Roboto";
		font-weight:bold;
		font-size:20px;
		color:#2D3033;
		/* text-align:center; */
		background-color:#F5E4A2;
	}

	.input_text_detail::placeholder {
		font-family: "Roboto";
		font-weight:bold;
		/* font-size:15px; */
		color:#461702;
	}

	.input_text_detail_icon_container {
		display:flex;
		align-items:center;
		justify-content:center;
		font-size:25px;
		padding:0 5px 0 5px;
		background-color:#FE7021;
		border-radius: 6px 0 0 6px;
		width:50px;
		color:#F7FAFF;
	}

	.input_text_detail_icon_container img {
		width:100%;
		/* margin: 7.5px 0 7.5px 0; */
	}

	.input_text_detail_readonly {
		color:#8E9091 !important;
	}
</style>
