<template>
	<div :class="'panel_container'+ computed_classess" ref="panel_container">
		<div class="panel_inner_container">
			<div class="panel_title" v-if="typeof $slots.title != 'undefined'"><div><slot name="title"></slot></div></div>
			<div class="panel_description" v-if="typeof $slots.description != 'undefined'"><slot name="description"></slot></div>
			<div class="panel_content" v-if="typeof $slots.content != 'undefined'"><slot name="content"></slot></div>
			<div class="panel_footer_title" v-if="typeof $slots.footerTitle != 'undefined'"><slot name="footerTitle"></slot></div>
			<div class="panel_buttons_container" v-if="typeof $slots.buttons != 'undefined'"><slot name="buttons"></slot></div>
		</div>
	</div>
</template>

<script>
	// import ComponentImportedName from './components/ComponentImportedName.vue'

	export default {
		name: 'ComponentExample',

		components: {
			// ComponentImportedName,
		},

		props: {
			propExample: String
		},

		created: function() {

		},

		mounted: function() {
			// console.log(this.$parent);
			// this.$parent.listenToAnim(this.$refs.panel_container);
			this.current_anim_class = " slideInAnim";
			if(this.$parent.isGoingToPrevious()) {
				this.current_anim_class += "-fromPrevious";
			}
		},

		beforeUnmount() {
			// this.$parent.removeListenToAnim(this.$refs.panel_container);
		},

		beforeUpdate: function() {

		},

		updated: function() {
			// this.$parent.listenToAnim(this.$refs.panel_container);
		},
		
		data() {
			return {
				current_anim_class: "",
				// going_to_next_page: false,
			}
		},

		filters: {
			filterExample: function(value) {
				let str = value;
				return str;
			}
		},

		computed: {
			computed_style: function() {
				let computed_style_str = "";
				return computed_style_str;
			},
			computed_classess: function() {
				let computed_classess_str = "";
				if(this.current_anim_class != null) {
					computed_classess_str += this.current_anim_class;
				}
				return computed_classess_str;
			},
		},

		methods: {
			/*isGoingToNextPage(value) {
				if(value != null && typeof value != "undefined") {
					this.going_to_next_page = value;
				}
				return this.going_to_next_page;
			},*/
			slideOut(reverse) {
				this.current_anim_class = " slideOutAnim";
				if(reverse) { this.current_anim_class += "-reverse"; }
				// this.current_anim_class = this.isGoingToNextPage() == true ? this.current_anim_class : this.current_anim_class+"-reverse";
			},
			slideIn(reverse) {
				this.current_anim_class = " slideInAnim";
				if(reverse) { this.current_anim_class += "-reverse"; }
				// this.current_anim_class = this.isGoingToNextPage() == true ? this.current_anim_class : this.current_anim_class+"-reverse";
			}
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	@font-face {
		font-family: "CustomFont";
		src: url('../assets/fonts/CustomFont.otf');
	}
	@font-face {
		font-family: "IncompatibleCustomFont";
		src: url('../assets/fonts/IncompatibleCustomFont.otf');
	}






	@font-face {
		font-family: "Anton";
		src: url('../assets/fonts/Anton-Regular.otf');
	}

	@font-face {
		font-family: "Open Sans";
		src: url('../assets/fonts/OpenSans-Regular.otf');
		font-weight: normal;
	}

	@font-face {
		font-family: "Open Sans";
		src: url('../assets/fonts/OpenSans-Bold.otf');
		font-weight: bold;
	}

	@font-face {
		font-family: "Open Sans";
		src: url('../assets/fonts/OpenSans-Italic.otf');
		font-style: italic;
	}

	@font-face {
		font-family: "Open Sans";
		src: url('../assets/fonts/OpenSans-BoldItalic.otf');
		font-weight: bold;
		font-style: italic;
	}






	@font-face {
		font-family: "Roboto";
		src: url('../assets/fonts/Roboto-Regular.otf');
		font-weight: normal;
	}

	@font-face {
		font-family: "Roboto";
		src: url('../assets/fonts/Roboto-Bold.otf');
		font-weight: bold;
	}

	@font-face {
		font-family: "Roboto";
		src: url('../assets/fonts/Roboto-Italic.otf');
		font-style: italic;
	}

	@font-face {
		font-family: "Roboto";
		src: url('../assets/fonts/Roboto-BoldItalic.otf');
		font-weight: bold;
		font-style: italic;
	}



	.panel_container {
		width:calc(100% - 30px);
		max-height:95vh;
		display:flex;
		background-color: #F7FAFF;
		margin:0 15px 0 15px;
		/* flex:1; */
		/* padding:15px 0 15px 0; */
		box-shadow: 1px -1px 5px 0px rgba(30, 32, 38,0.75);

		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;

		border-radius:12px;
		border-top-left-radius: 50px;
	}

	

	@media all and (orientation: landscape) {
		.panel_container {
			max-width:640px;
			flex:0;
		}
	}
	/*@media all and (orientation: landscape) and (max-height: 720px) {
		
	}
	@media all and (orientation: landscape) and (max-height: 500px) {
		
	}
	@media all and (orientation: portrait) {
		
	}*/



	.panel_inner_container {
		width:calc(100% - 0px);
		display:flex;
		background-color: transparent;
		/* margin:15px 0 15px 0; */
		border-radius:0;
		flex:1;
		border-style:solid;
		border-width: 0;
		/* border-top-width: 5px; */
		/* border-bottom-width: 2px; */
		border-color:#fdef54;
		flex-direction:column;
		align-items:center;
		justify-content:center;
	}

	.panel_title {
		width:calc(90% - 15px);
		padding:15px 7.5px 15px 7.5px;
		/* margin-bottom:10px; */
		display:flex;
		align-items:center;
		justify-content:center;
		text-align:center;
		font-size:17px;
		color:#461702;
		font-family: "Roboto";
		/* font-weight:bold; */
		/* text-shadow: 1px 1px 1.5px rgba(114, 46, 7, 1.0); */
		background-color:#FEBA33;
		/* letter-spacing:1.1px; */
		flex-direction:row;
		border-radius:10px;
		transform: skewX(-10deg);
		margin-top:-5px;
		margin-left:-10%;
		box-shadow: inset 0px 5px 0px 3.5px rgba(255,232,183,1.0), inset 0px -1.5px 0px 3.5px #FE7021;
		border-style:solid;
		border-color:#31293E;
		border-width:2.5px;




		/* background: rgb(254,246,181);
		background: -moz-linear-gradient(180deg, rgba(254,246,181,1) 6%, rgba(254,186,51,1) 10%);
		background: -webkit-linear-gradient(180deg, rgba(254,246,181,1) 6%, rgba(254,186,51,1) 10%);
		background: linear-gradient(180deg, rgba(254,246,181,1) 6%, rgba(254,186,51,1) 10%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fef6b5",endColorstr="#feba33",GradientType=1);  */
		background: rgb(254,112,33);
		background: -moz-linear-gradient(45deg, rgba(254,112,33,1) 0%, rgba(254,186,51,1) 50%);
		background: -webkit-linear-gradient(45deg, rgba(254,112,33,1) 0%, rgba(254,186,51,1) 50%);
		background: linear-gradient(45deg, rgba(254,112,33,1) 0%, rgba(254,186,51,1) 50%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fe7021",endColorstr="#feba33",GradientType=1);  
	}

	
	.panel_title div {
		width:calc(100% - 0px);
		display:flex;
		align-items:center;
		justify-content:center;
		text-align:center;
		flex-direction:row;
		transform: skewX(10deg);
	}

	.panel_description {
		width:calc(100% - 15px);
		padding:10px 7.5px 10px 7.5px;
		margin-bottom:10px;
		display:flex;
		align-items:center;
		justify-content:center;
		text-align:center;
		font-size:15px;
		color:#151515;
		font-family: "Roboto";
		/* text-shadow: 1px 1px 1px rgba(30, 32, 38,0.75); */
		/* background-color:#262938; */
		/* letter-spacing:1.5px; */
		flex-direction:column;
	}

	.panel_footer_title {
		width:calc(100% - 15px);
		padding:15px 7.5px 15px 7.5px;
		margin-top:-7.5px;
		margin-bottom:10px;
		display:flex;
		align-items:center;
		justify-content:center;
		text-align:center;
		font-size:12px;
		color:#fdfdfd;
		font-family: "Roboto";
		text-shadow: 1px 1px 1px rgba(30, 32, 38,0.75);
		background-color:#262938;
		/* letter-spacing:1.5px; */
		flex-direction:column;
	}

	.panel_buttons_container {
		width:calc(100% - 15px);
		padding:15px 7.5px 15px 7.5px;
		display:flex;
		align-items:stretch;
		justify-content:stretch;
		text-align:center;
		font-size:17px;
		color:#fdfdfd;
		font-family: "CustomFont";
		text-shadow: 1px 1px 1px rgba(30, 32, 38,0.75);
		/* background-color:#482997; */
		letter-spacing:1.5px;
		flex-direction:row;
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
	}

	.slideInAnim {
		animation: slideIn 0.15s forwards;
		animation-timing-function: ease;
	}

	.slideOutAnim {
		animation: slideOut 0.15s forwards;
		animation-timing-function: ease;
	}
	
	.slideInAnim-reverse {
		animation: slideIn-reverse 0.15s forwards;
		animation-timing-function: ease;
	}
	
	.slideInAnim-fromPrevious {
		animation: slideIn-fromPrevious 0.15s forwards;
		animation-timing-function: ease;
	}

	.slideOutAnim-reverse {
		animation: slideOut-reverse 0.15s forwards;
		animation-timing-function: ease;
	}

	@keyframes slideIn {
		from {
			transform: translateX(100vw); 
		}
		to {
			transform: translateX(0px); 
		}
	}

	@keyframes slideOut {
		from {
			transform: translateX(0px); 
		}
		to {
			transform: translateX(-100vw); 
		}
	}

	@keyframes slideIn-reverse {
		from {
			transform: translateX(0px); 
		}
		to {
			transform: translateX(100vw); 
		}
	}

	@keyframes slideIn-fromPrevious {
		from {
			transform: translateX(-100vw);
		}
		to {
			transform: translateX(0px);
		}
	}

	@keyframes slideOut-reverse {
		from {
			opacity:1;
			transform: translateX(0px); 
		}
		to {
			opacity:0;
			transform: translateX(-100vw); 
		}
	}
</style>
