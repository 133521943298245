<template>
	<div class="amount_input_container" :style="computed_style">
		<div class="amount_input_image_container" v-if="src != null && typeof src != 'undefined'">
			<img :src="src" />
		</div>
		<div class="amount_input_inputfield_container">
			<input type="text" ref="amount_input_value" :placeholder="computed_placeholder" :value="computed_value"  maxlength="9"/>
		</div>
		<div class="amount_input_max_button" :onClick="()=>{setMaxAmount();}">
			MAX
		</div>
	</div>
</template>

<script>
	// import ComponentImportedName from './components/ComponentImportedName.vue'

	export default {
		name: 'AmountInput',

		components: {
			// ComponentImportedName,
		},

		props: {
			value: String,
			placeholder: String,
			src: String,
			// id: String,
			// onPressEnter: Function,
			save: Function,
		},

		created: function() {
			
		},
		
		data() {
			return {
				amount:0,
			}
		},

		filters: {
			filterExample: function(value) {
				let str = value;
				return str;
			}
		},

		computed: {
			computed_style: function() {
				let computed_style_str = "";
				return computed_style_str;
			},
			computed_placeholder: function() {
				let computed_placeholder_str = "";
				if(this.placeholder != null && typeof this.placeholder != "undefined") {
					computed_placeholder_str = this.placeholder;
				}
				// console.log("placeholder: "+this.placeholder);
				// console.log("placeholder: "+computed_placeholder_str);
				return computed_placeholder_str;
			},
			computed_value: function() {
				let computed_value_str = "";
				if(this.value != null && typeof this.value != "undefined") {
					computed_value_str = this.value == 0 ? "" : this.value;
				}
				// console.log("value: "+this.value);
				// console.log("value: "+computed_value_str);
				return computed_value_str;
			},
		},

		updated () {
			// this.filterInputText();
		},

		mounted () {
			// this.loadAmount();
			let amount_input_value = this.$refs.amount_input_value;
			amount_input_value.addEventListener("change",this.filterInputText);
			amount_input_value.addEventListener("paste",this.filterInputText);
			amount_input_value.addEventListener("keyup",this.filterInputText);
			amount_input_value.addEventListener("ready",this.filterInputText);
			amount_input_value.addEventListener("load",this.filterInputText);
			this.filterInputText();
			if(this.getAmount() <= 0) {
				amount_input_value.value="";
			}
		},

		methods: {
			setMaxAmount() {
				this.$refs.amount_input_value.value="999999999";
				this.filterInputText();
			},
			getAmount() {
				let amount_temp = parseInt(this.amount);
				amount_temp = isNaN(amount_temp) != true ? amount_temp : 0;
				return amount_temp;
			},
			setAmount(value) {
				this.amount = value;
			},
			filterInputText() {
				let amount_input_value = this.$refs.amount_input_value;
				let value = String(amount_input_value.value).trim();
				if(value.trim() == "") {
					amount_input_value.value="";
				} else {
					let fixed_value = parseInt(value.replace(/[^0-9]/g,''));
					fixed_value = String(fixed_value) == "NaN" ? 0 : fixed_value;
					amount_input_value.value = fixed_value;
				}
				let inputValue = isNaN(parseInt(this.$refs.amount_input_value.value)) ? 0 : parseInt(this.$refs.amount_input_value.value);
				this.setAmount(inputValue);
				this.saveAmount();
				// console.log("inputValue: "+inputValue);
			},
			saveAmount() {
				// localStorage.setItem("amount_"+String(this.id), String(this.amount));
				if(this.save != null && typeof this.save != "undefined") { this.save(); }
				// this.$parent.setUserAmountDetail(this.getAmount());
			},
			/*loadAmount() {
				let storedAmount = parseInt(localStorage.getItem("amount_"+String(this.id)));
				if(storedAmount != null && typeof storedAmount != "undefined" && isNaN(storedAmount) != true) {
					this.setAmount(storedAmount);
					this.$refs.amount_input_value.value=this.amount;
				}
			}*/
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

	@font-face {
		font-family: "CustomFont";
		src: url('../assets/fonts/CustomFont.otf');
	}
	@font-face {
		font-family: "IncompatibleCustomFont";
		src: url('../assets/fonts/IncompatibleCustomFont.otf');
	}






	@font-face {
		font-family: "Anton";
		src: url('../assets/fonts/Anton-Regular.otf');
	}

	@font-face {
		font-family: "Open Sans";
		src: url('../assets/fonts/OpenSans-Regular.otf');
		font-weight: normal;
	}

	@font-face {
		font-family: "Open Sans";
		src: url('../assets/fonts/OpenSans-Bold.otf');
		font-weight: bold;
	}

	@font-face {
		font-family: "Open Sans";
		src: url('../assets/fonts/OpenSans-Italic.otf');
		font-style: italic;
	}

	@font-face {
		font-family: "Open Sans";
		src: url('../assets/fonts/OpenSans-BoldItalic.otf');
		font-weight: bold;
		font-style: italic;
	}






	@font-face {
		font-family: "Roboto";
		src: url('../assets/fonts/Roboto-Regular.otf');
		font-weight: normal;
	}

	@font-face {
		font-family: "Roboto";
		src: url('../assets/fonts/Roboto-Bold.otf');
		font-weight: bold;
	}

	@font-face {
		font-family: "Roboto";
		src: url('../assets/fonts/Roboto-Italic.otf');
		font-style: italic;
	}

	@font-face {
		font-family: "Roboto";
		src: url('../assets/fonts/Roboto-BoldItalic.otf');
		font-weight: bold;
		font-style: italic;
	}


	.amount_input_container {
		display:flex;
		flex:1;
		/* background-color:#ff00ff; */
		width:calc(100% - 0px);
		margin:7.5px 0 7.5px 0;
	}

	.amount_input_image_container {
		display:flex;
		/* width:50px; */
		align-items:center;
		justify-content:center;
		padding-right:7.5px;
	}

	.amount_input_image_container img {
		width:50px;
	}

	.amount_input_inputfield_container {
		display:flex;
		align-items:stretch;
		justify-content:stretch;
		border-radius:6px 0 0 6px;
		flex:1;
		align-self:stretch;
		justify-self:stretch;
	}

	.amount_input_inputfield_container input {
		width:calc(100% - 20px);
		border-style:solid;
		border-width:0;
		border-radius:6px 0 0 6px;
		padding:15px 5px 15px 15px;
		font-family: "Roboto";
		font-weight:bold;
		font-size:17px;
		color:#461702;
		background-color:#F5E4A2;
	}

	.amount_input_inputfield_container input::placeholder {
		font-family: "Roboto";
		font-weight:bold;
		font-size:14px;
		color:#461702;
	}

	.amount_input_max_button {
		width:50px;
		display:flex;
		text-align:center;
		align-items:center;
		justify-content:center;
		padding:20px 2.5px 20px 2.5px;
		border-style:solid;
		border-radius:0 6px 6px 0;
		border-width: 0;
		border-left-width: 1px;
		border-color:#461702;
		cursor:pointer;

		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;

		text-shadow: 0px 0px 0px rgba(0, 0, 0,0.0);

		font-family: "Roboto";
		font-weight:bold;
		text-transform:uppercase;
		letter-spacing:0;
		font-size:15px;

		background: rgb(254,112,33);
		background: -moz-linear-gradient(30deg, rgba(254,112,33,1) 50%, rgba(255,133,68,1) 100%);
		background: -webkit-linear-gradient(30deg, rgba(254,112,33,1) 50%, rgba(255,133,68,1) 100%);
		background: linear-gradient(30deg, rgba(254,112,33,1) 50%, rgba(255,133,68,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fe7021",endColorstr="#ff8544",GradientType=1); 
		color:#ffffff;
		border-style:solid;
		border-width:0;
		border-color:#BB4102;
		box-shadow:  inset 0px 0px 0px 2px #BB4102, inset 1px 1px 0px 3px #FB8849;
		text-shadow:1px 1px 1px rgba(152, 81, 59, 0.90), -1px 1px 1px rgba(152, 81, 59,0.90), 1px -1px 1px rgba(152, 81, 59,0.90), -1px -1px 1px rgba(152, 81, 59,0.90), 0px 0px 5px rgba(0,30,0,0.50);
	}

	.amount_input_max_button:hover, .amount_input_max_button:active {
		background-color:#2277EE !important;
		color:#FFFFFF !important;
		border-color:#1750A1 !important;
	}
</style>
