<template>
	<div :class="'panel_button_container'+computed_classes" :style="computed_style">
		<slot></slot>
	</div>
</template>

<script>
	// import ComponentImportedName from './components/ComponentImportedName.vue'

	export default {
		name: 'PanelButton',

		components: {
			// ComponentImportedName,
		},

		props: {
			affirmative: Boolean,
			negative: Boolean,
			marginLeft: String,
			marginRight: String,
			marginTop: String,
			marginBottom: String,
		},

		created: function() {

		},
		
		data() {
			return {
				
			}
		},

		filters: {
			filterExample: function(value) {
				let str = value;
				return str;
			}
		},

		computed: {
			computed_classes: function() {
				let computed_classes_str = "";

				
				try {
					if(this.affirmative) {
						computed_classes_str += " panel_button_ok";
					} else if(this.negative) {
						computed_classes_str += " panel_button_cancel";
					} else {
						computed_classes_str += " panel_button_neutral";
					}
				} catch {
					computed_classes_str += " panel_button_neutral";
				}

				return computed_classes_str;
			},
			computed_style: function() {
				let computed_style_str = "";

				if(this.marginLeft != null && typeof this.marginLeft != "undefined") {
					computed_style_str += "margin-left: "+this.marginLeft+";";
				}

				if(this.marginRight != null && typeof this.marginRight != "undefined") {
					computed_style_str += "margin-right: "+this.marginRight+";";
				}

				if(this.marginTop != null && typeof this.marginTop != "undefined") {
					computed_style_str += "margin-top: "+this.marginTop+";";
				}

				if(this.marginBottom != null && typeof this.marginBottom != "undefined") {
					computed_style_str += "margin-bottom: "+this.marginBottom+";";
				}

				return computed_style_str;
			},
		},

		methods: {
			exampleMethod: function () {
				return '<div>example</div>';
			},

			componentToHex: function(c) {
				var hex = c.toString(16);
				return hex.length == 1 ? "0" + hex : hex;
			},

			rgbToHex: function(r, g, b) {
				return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
			},

			hexToRgb: function(hex) {
				var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
				return result ? {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16)
				} : null;
			}
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

	@font-face {
		font-family: "CustomFont";
		src: url('../assets/fonts/CustomFont.otf');
	}
	@font-face {
		font-family: "IncompatibleCustomFont";
		src: url('../assets/fonts/IncompatibleCustomFont.otf');
	}






	@font-face {
		font-family: "Anton";
		src: url('../assets/fonts/Anton-Regular.otf');
	}

	@font-face {
		font-family: "Open Sans";
		src: url('../assets/fonts/OpenSans-Regular.otf');
		font-weight: normal;
	}

	@font-face {
		font-family: "Open Sans";
		src: url('../assets/fonts/OpenSans-Bold.otf');
		font-weight: bold;
	}

	@font-face {
		font-family: "Open Sans";
		src: url('../assets/fonts/OpenSans-Italic.otf');
		font-style: italic;
	}

	@font-face {
		font-family: "Open Sans";
		src: url('../assets/fonts/OpenSans-BoldItalic.otf');
		font-weight: bold;
		font-style: italic;
	}






	@font-face {
		font-family: "Roboto";
		src: url('../assets/fonts/Roboto-Regular.otf');
		font-weight: normal;
	}

	@font-face {
		font-family: "Roboto";
		src: url('../assets/fonts/Roboto-Bold.otf');
		font-weight: bold;
	}

	@font-face {
		font-family: "Roboto";
		src: url('../assets/fonts/Roboto-Italic.otf');
		font-style: italic;
	}

	@font-face {
		font-family: "Roboto";
		src: url('../assets/fonts/Roboto-BoldItalic.otf');
		font-weight: bold;
		font-style: italic;
	}


	.panel_button_container {
		display:flex;
		flex:1;
		text-align:center;
		align-items:center;
		justify-content:center;
		padding:20px 2.5px 20px 2.5px;
		margin-left:15px;
		margin-right:15px;
		/* border-radius:1.5px; */
		cursor:pointer;

		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;

		text-shadow: 0px 0px 0px rgba(0, 0, 0,0.0);

		font-family: "Roboto";
		font-weight:bold;
		text-transform:uppercase;
		letter-spacing:0;
		font-size:15px;

		position:relative;

		border-top-left-radius: 100px;
		border-bottom-left-radius: 100px;
		border-top-right-radius: 100px;
		border-bottom-right-radius: 100px;
	}

	.panel_button_ok {
		/*background-color:#FCEE43;
		background: rgb(255,255,220);
		background: -moz-linear-gradient(180deg, rgba(255,255,220,1) 8%, rgba(255,238,56,1) 15%, rgba(255,238,56,1) 24%, rgba(253,210,36,1) 100%);
		background: -webkit-linear-gradient(180deg, rgba(255,255,220,1) 8%, rgba(255,238,56,1) 15%, rgba(255,238,56,1) 24%, rgba(253,210,36,1) 100%);
		background: linear-gradient(180deg, rgba(255,255,220,1) 8%, rgba(255,238,56,1) 15%, rgba(255,238,56,1) 24%, rgba(253,210,36,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffdc",endColorstr="#fdd224",GradientType=1); 
		box-shadow: inset 0px 0px 0px 1.5px #FFFEE1;

		color:#ffffff;
		text-shadow:1px 1px 1px rgba(110,87,17,0.90), -1px 1px 1px rgba(110,87,17,0.90), 1px -1px 1px rgba(110,87,17,0.90), -1px -1px 1px rgba(110,87,17,0.90), 0px 0px 5px rgba(0,30,0,0.50);
		letter-spacing:1.1px;*/

		/* background: rgb(255,133,68);
		background: -moz-linear-gradient(-90deg, rgba(255,133,68,1) 24%, rgba(254,109,28,1) 100%);
		background: -webkit-linear-gradient(-90deg, rgba(255,133,68,1) 24%, rgba(254,109,28,1) 100%);
		background: linear-gradient(-90deg, rgba(255,133,68,1) 24%, rgba(254,109,28,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff8544",endColorstr="#fe6d1c",GradientType=1);box-shadow: inset 0px 0px 0px 1.5px #FFFEE1; */

		background: rgb(254,112,33);
		background: -moz-linear-gradient(30deg, rgba(254,112,33,1) 50%, rgba(255,133,68,1) 100%);
		background: -webkit-linear-gradient(30deg, rgba(254,112,33,1) 50%, rgba(255,133,68,1) 100%);
		background: linear-gradient(30deg, rgba(254,112,33,1) 50%, rgba(255,133,68,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fe7021",endColorstr="#ff8544",GradientType=1); 

		color:#ffffff;
		border-style:solid;
		border-width:0;
		border-color:#BB4102;
		box-shadow: inset 1px 1px 0px 2px #FB8849, 0px 0px 0px 3px #BB4102;
		text-shadow:1px 1px 1px rgba(152, 81, 59, 0.90), -1px 1px 1px rgba(152, 81, 59,0.90), 1px -1px 1px rgba(152, 81, 59,0.90), -1px -1px 1px rgba(152, 81, 59,0.90), 0px 0px 5px rgba(0,30,0,0.50);
		letter-spacing:1.1px; 
	}

	.panel_button_ok:hover, .panel_button_ok:active {
		/* background-color:#2277EE !important;
		color:#FFFFFF !important; */
		
		background: rgb(254,112,33);
		background: -moz-linear-gradient(30deg, rgba(254,112,33,1) 50%, rgba(255,133,68,1) 100%);
		background: -webkit-linear-gradient(30deg, rgba(254,112,33,1) 50%, rgba(255,133,68,1) 100%);
		background: linear-gradient(30deg, rgba(254,112,33,1) 50%, rgba(255,133,68,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fe7021",endColorstr="#ff8544",GradientType=1); 

		color:#ffffff;
		border-style:solid;
		border-width:0;
		border-color:#BB4102;
		box-shadow: inset 1px 1px 0px 2px #FB8849, 0px 0px 0px 3px #BB4102;
		text-shadow:1px 1px 1px rgba(152, 81, 59, 0.90), -1px 1px 1px rgba(152, 81, 59,0.90), 1px -1px 1px rgba(152, 81, 59,0.90), -1px -1px 1px rgba(152, 81, 59,0.90), 0px 0px 5px rgba(0,30,0,0.50);
		letter-spacing:1.1px; 
	}

	.panel_button_neutral, .panel_button_cancel {
		/* background-color:#58606E;
		color:#D3D5D9; */

		background: rgb(28,198,198);
		background: -moz-linear-gradient(30deg, rgba(28,198,198,1) 50%, rgba(64,214,215,1) 100%);
		background: -webkit-linear-gradient(30deg, rgba(28,198,198,1) 50%, rgba(64,214,215,1) 100%);
		background: linear-gradient(30deg, rgba(28,198,198,1) 50%, rgba(64,214,215,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1cc6c6",endColorstr="#40d6d7",GradientType=1); 

		color:#ffffff;
		border-style:solid;
		border-width:0;
		border-color:#17807C;
		box-shadow: inset 1px 1px 0px 2px #40d6d7, 0px 0px 0px 3px #17807C;
		text-shadow:1px 1px 1px rgba(23, 128, 124, 0.90), -1px 1px 1px rgba(23, 128, 124,0.90), 1px -1px 1px rgba(23, 128, 124,0.90), -1px -1px 1px rgba(23, 128, 124,0.90), 0px 0px 5px rgba(0,30,0,0.50);
		letter-spacing:1.1px; 
	}

	/* .panel_button_neutral:hover, .panel_button_neutral:active {
		background-color:#E3EFFF !important;
		color:#2D3033 !important;
	}

	.panel_button_cancel:hover, .panel_button_cancel:active {
		background-color:#CD5152 !important;
		color:#FDF6F6 !important;
	} */
</style>
